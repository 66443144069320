import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSWFCompat from 'react-swf/compat';
import RecordRTCTest from '../component/reactRTC';
import ReactMicCom from '../component/ReactMic'
import '../assets/sub/sstyle.css';


class volumeRecordingTest extends Component {    
    constructor(props){
        super(props);

        this.state={
            _dispAudioVisual:null,           
            myRInterval:null,
            _rate:0,
            _nTime:0
        
        }
    }
    thisMovie(movieName){
        return document[movieName];
    }	

    GoNext=()=>{
        let self = this;

        this.refs.child.stopRecording();
        if(self.state.myRInterval)
            clearInterval(self.state.myRInterval);                                

        setTimeout(function() {
            //self.props.history.push('/ready/9/'+self.props.courseno);
            self.props.history.push('/test/'+self.props.courseno+'/1/0');
           },1000)        
        
    }


    sendToRecordStart=()=>{
        //record stop()
        //this.thisMovie('kau_record').receive3();
        // this.refs.child.btnStartRecording();
        this.startRTimer('R');
    }

    sendToRecordStop=()=>{
        //record stop()
        /*
        if(this.thisMovie('kau_record')){
          
           this.thisMovie('kau_record').receive2();
          }            
          */
         //this.refs.child.btnStopRecording();
         if(this.state.myRInterval)
            clearInterval(this.state.myRInterval);                                
    }      
    rndSoundFile=()=> {
        var _soundFile= null;
        var _curDate= new Date();
        var _mDate = "";
        var _mMonth = "";
        var _mHour = "";
        var _mSecond = "";
        var _mMinute = "";
        var _ymd = "";
        
        if(_curDate.getMonth() < 10)
            _mMonth = "0" + (_curDate.getMonth()+1)
        else	
            _mMonth = "" + (_curDate.getMonth()+1)
        
        if(_curDate.getDate() < 10)
            _mDate = "0" + _curDate.getDate()
        else
            _mDate = "" + _curDate.getDate()
        
        if(_curDate.getHours() < 10)
            _mHour = "0" + _curDate.getHours()
        else
            _mHour = ""	+ _curDate.getHours()
        
        if(_curDate.getMinutes() < 10)
            _mMinute = "0" + _curDate.getMinutes();
        else
            _mMinute = ""	+ _curDate.getMinutes();
        
        
        if(_curDate.getSeconds() < 10)
            _mSecond = "0" + _curDate.getSeconds()
        else
            _mSecond = ""	+ _curDate.getSeconds()
        
        
        _ymd = _curDate.getUTCFullYear() + _mMonth;		
        
        _soundFile =  _curDate.getUTCFullYear() + _mMonth + _mDate + _mHour + _mMinute + _mSecond+"_1_test";
        //+this.state._uno

        console.log(_soundFile);
        
        return _soundFile
        
    }             
    showVisualize(_this,mic){
        _this.state._dispAudioVisual= mic;
        //_this.setState({dispAudioVisual: mic});
    }    

    startRTimer=(_type)=>{

        let self = this;

        if(self.state.myRInterval)
            clearInterval(self.state.myRInterval);   

        console.log('pre interval=' + this.state.myRInterval);
        this.state.myRInterval = setInterval(() => {
                console.log('interval=' + this.state.myRInterval);
                        
                console.log('record seconds='+self.state._nTime);        
                
                if(document.querySelector('.time_state'))
                    document.querySelector('.time_state').innerHTML = ((parseInt(parseInt(self.state._nTime)/60)) < 10 ? '0'+(parseInt(parseInt(self.state._nTime)/60)) :(parseInt(parseInt(self.state._nTime)/60)))  + " : " + ((parseInt(self.state._nTime)%60) < 10 ? '0'+(parseInt(self.state._nTime)%60) :(parseInt(self.state._nTime)%60)) ;   
               

                self.state._nTime ++;
            
                if(self.state._nTime > 300 ){
                    console.log('clear record seconds');
                    clearInterval(this.state.myRInterval);            
                    
                    this.sendToRecordStop()

                    //setTimeout( this.GoNext(), 2000);                   
                    
                }           
            

            

                console.log('ntime=',this.state._nTime);

        }, 1000)
    }

    render() {
        return (
            <div className="body">               
                
                <div className="contents_guide1">
                    <div className="text_box">
                        <div className="title">Volume Setting &amp; Recording Test</div>
                        <div className="basic_type">
                            <p className="kr14"><b>1. 자신의 목소리가 잘 녹음 되도록 해드셋의 마이크 위치를 확인하세요.</b></p>
                            <p className="en14">(LOCATE THE MIKE ON A PROPER POSITION TO RECORD YOUR VOICE WELL)</p>
                            <p className="kr14"><b>2. 조절이 끝나면 " RECORD" 버튼을 누르고, 다음의 문구를 10초안에 크게 읽으세요. "HELLO ~~~ EPTA!!!"</b></p>
                            <p className="en14">(CLICK THE "RECORD" BUTTON THEN READ THE FOLLOWING SENTENCE "HELLO ~~~ EPTA!!!" WITHIN 10 SECONDS.)</p>
                            <p className="kr14"><b>3. 녹음이 끝나고 "PLAY" 버튼을 누른 후 녹음된 소리가 잘 나오는지 확인하세요.</b></p>
                            <p className="en14">(CLICK THE "PLAY" BUTTON AND CHECK YOUR RECORD)</p>
                          
                            <div className="vol_set">
                            <div style={{margin: '0 auto'}}>
                              <ReactMicCom  width={820} ref="child" disptype={'T'} filename={this.rndSoundFile()} sendToRecordStart={this.sendToRecordStart} sendToRecordStop={this.sendToRecordStop}/>
                                    
                            </div>
                            </div>
                        </div>
                    </div>                  
                </div>
                <div className="btn_only"><img id="id_Next" src="../Images/btn_confirm_normal.png" onload="InitButtion(this, false);" style={{ cursor: 'default',marginTop:-40 }} onClick={this.GoNext}/></div>
            </div>
        );
    }
}

export default volumeRecordingTest;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSWFCompat from 'react-swf/compat';
// import Jumbotron from 'react-bootstrap/Jumbotron';
// import Toast from 'react-bootstrap/Toast';
// import Container from 'react-bootstrap/Container';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';
import axios from 'axios';

class SeatInfo extends Component {    
    
    constructor(props){
        super(props);
        this.state={
            _courses: null,
            _loaded: false       
        }
    }
    componentDidMount=()=>{
        this._getCourseInfo();
    }


    thisMovie(movieName){
        return document[movieName];
    }	
    GoNext=()=>{
        // this.props.history.push('test/'+this.props.courseno+'/1');
        this.props.history.push('ready/7/'+this.props.courseno);
    }  

    _getCourseInfo(){
        
        const apiUrl = 'https://tickau.engpool.com/cbt/ajax_course_info.cshtml?cno='+this.props.courseno+'&uno='+window.users.userno;

        axios.get(apiUrl)
            .then(data => {
                this.setState({
                    _courses: data,
                    _loaded: true
                });
                console.log("get courseinfo re render");
            })
            .catch(error => {
                console.log(error);
                alert('데이터를 가져오는데 실패했습니다.');
            });
    }

    render(){
        document.querySelector('body').style='background-color: #3ec2c6';

        if(!this.state._loaded)
        return(<div>loading</div>)

        let title = this.state._courses.data.rows[0].s_name;
        
    return (
        <div>
        <div className="Last_box">
        <img src="../../images/ico_study.png"/>
        <h1>기초통합학습</h1>
        <div style={{marginTop:20}}><span>{title}</span></div>
        
        <div className="btn_area">
         <button className="btn_start" id=""  onClick={this.GoNext}>시작하기</button> 
        </div>
        </div>
        </div>
    )
    }
}
export default SeatInfo;
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import Header from "../component/Header";
import Question from "../component/Question";
import QuestionLevel from "../component/QuestionLevel";
import Direction from "../component/Direction";
import { Link } from 'react-router-dom';
// import '../assets/sub/common.css';
import '../assets/question/TypeCommon.js';
// import '../assets/question/qstyle.css';

import { reduceEachTrailingCommentRange } from 'typescript';

class QuestionHome extends Component{
    constructor(props){
        super(props);

        this.state = {
            _qno    :   this.props.match.params.seqno,
            _sno    :   props.match.params.setno,            
            _userno : window.users.userno,            
            _Qnum   : 1,
			_Next_timer     : null,
			nNoVoiceCnt     : 0,
            nLowVoiceCnt    : 0,
            _setno          :  props.match.params.setno,
            _nowQuiz        :  parseInt(props.match.params.seqno, 10),
            _Questions       : [],
            _HomeMinutes        :1,
            _HomeSeconds        :0,            
            Qtype: 'QST',            
            _QuizUrl        : "https://file2.engpool.com/toeic/epta/testnewepta/kau/basic/SET",
            _DirSounds      : ["P1TA_DIR.mp3", "P1TB_DIR.mp3", "P2TA_DIR.mp3", "P2TB_DIR.mp3", "P2TC_DIR.mp3"],            
            _QSounds        : [],
            _Directions:    [],
            _QImages :      [],
            _QText :        [],
            _userAnswer:    [],
            _DirQText       :[""],            
            _DirQImg        :[""],            
            _i : 0,
            qloading        : true,
            dloading        : true,
            _myInterval     : []
            

        };	       

       
        this.t_time = 0;
    this._trycnt = this.props.match.params.trycnt;
        
    }

    
    startTimer(){     
        
        this._myHomeInterval = setInterval(() => {
            const { _HomeSeconds, _HomeMinutes } = this.state

            if (_HomeSeconds > 0) {
                this.setState(({ _HomeSeconds }) => ({
                    _HomeSeconds: _HomeSeconds - 1
                }))
            }
            if (_HomeSeconds === 0) {
                if (_HomeMinutes === 0) {
                    clearInterval(this._myHomeInterval)
                } else {
                    this.setState(({ _minutes }) => ({
                        _HomeMinutes: _HomeMinutes - 1,
                        _HomeSeconds: 59
                    }))
                }
            } 

            console.log('home minutes='+this.state._HomeMinutes);
            console.log('home seconds='+this.state._HomeSeconds);

            this.SetExamRemainTime(this.state._HomeMinutes +':'+this.state._HomeSeconds,this.state._HomeSeconds*60 + this.state._HomeSeconds);

        }, 1000)
    }

    componentWillUnmount(){
        
    }

    componentDidMount(){

         this._getQuestions();
         //this.startTimer();
       // this._getDirections();
        //this.populateWeatherData();
	}

	componentWillReceiveProps(nextProps) {
		//요일이 바뀌면 다시 setState 처리
        console.log("444");
        /*
     if(this.state._nowQuiz != nextProps.match.params.seqno){
            this.setState({
                _nowQuiz : nextProps.match.params.seqno
            })
        }   
      */
     
       if(String(this.props.match.params.seqno) != "undefined" && this.state._nowQuiz != nextProps.match.params.seqno){
        this.setState({
            _nowQuiz : nextProps.match.params.seqno
        })


        
      } 
      
     

  
    }
    /*
    shouldComponentUpdate(nextProps){
        console.log("444");

		if (this.state.stepNo !== parseInt(nextProps.match.params.stepno, 10)) {
			this.setState({
				stepNo: parseInt(nextProps.match.params.stepno, 10)
			});
        }

        //this.populateWeatherData();
        

    }
    */

    componentDidUpdate(prevProps){
        //요일이 바뀌면 다시 setState 처리
        /*
        let prevQuery = new URLSearchParams(prevProps.location.search);
        let prevDay = prevQuery.get('day');

        let query = new URLSearchParams(this.props.location.search);
        let day = query.get('day');

        if(prevDay !== day){
            this.setState({
                day
            })
        };
        */
                   
       console.log("444");
    }

    _getQuestions(){
        //webtoon_list를 가지고 옵니다.
        //const apiUrl = '/dummy/webtoon_list.json';
        const apiUrl = 'https://tickau.engpool.com/cbt/ajax_question_list.cshtml?cno='+this.state._sno+'&uno='+this.state._userno;

        axios.get(apiUrl)
            .then(data => {
                //가지고 온 리스트를 state에 저장합니다.

                this.setState({
                    _Questions: data,
                    qloading: false,
                    _QuizUrl: this.state._QuizUrl + this.props.match.params.setno
                });
                
            })
            .catch(error => {
                console.log(error);
                this.state.qloading = true
            });
    }


    reunderTaskNum(){
        
    }

    renderLoading(){
     
        
    }
   

    sub_render(){
        
       
    }

    render(){


        const NoData = this.state.qloading;        
        document.querySelector('body').style='background-color: ';
        
        if(!this.state._Questions.data)
        return (
            <div>
                        <p><em>Loading...</em></p>    
            </div>)
    
        const nowQuestions = this.state._Questions.data
                
        if(!this.state._nowQuiz)
            this.state._nowQuiz=0;      


        const _no  = this.state._nowQuiz - 1;

        if(!this._trycnt ||this._trycnt==0)
            this._trycnt = 1;
            

        if(this.state._setno == undefined)
            this.state._setno=1;        

        let lastquiz = parseInt(nowQuestions.QuizCount) ===  parseInt(this.state._nowQuiz) ? "Y":"N"; //mrdo 20210117
        let qType = parseInt(nowQuestions.QuizCount) <  parseInt(this.state._nowQuiz) ? "END":
             parseInt(nowQuestions.rows[_no].QueType)  === 3  ? ("DIR")  : "QUIZ";

             console.log('nowQuestions.QuizCount=',nowQuestions.QuizCount);
             console.log('this.state._nowQuiz=',this.state._nowQuiz);
    
        if (qType=="END"){

            this.t_time = 0;
            if(!this.refs.child)
                this.t_time = 0;
            else{
                this.t_time = this.refs.child._timeSeconds;
            
                if(this.refs.child.state)
                    clearInterval(this.refs.child.state.TInterval);
            }

             return(     
                 <Redirect to={"/examend/"+ this.state._setno+"/"+this.t_time+"/"+this._trycnt}/>
             )   
            }
 
        
        if(this.state._Questions.data.NQueno > 0 && parseInt(this.state._Questions.data.rows[_no].QueNum) === 1 && this._trycnt===1)
            return(
                    <Redirect to={"/test/"+ this.state._setno + "/"+ (parseInt(this.state._Questions.data.NQueno)+1)+"/"+this._trycnt}/>
                )        


        return (	
        <div>
                      

            {            
            qType === "DIR" ?
                <Direction uno={this.state._userno} history={this.props.history} qurl={this.state._QuizUrl} sno={this.state._setno} qno={this.state._nowQuiz} questions={nowQuestions.rows[_no]} trycount={this._trycnt}/>                        
               :
                <Question ref="child" uno={this.state._userno} history={this.props.history} qurl={this.state._QuizUrl} sno={this.state._setno} qno={this.state._nowQuiz} questions={nowQuestions.rows[_no]} partinfo={nowQuestions.rows1[0]} qcount={nowQuestions.QuizCount} flag={lastquiz} trycount={this._trycnt}/>   //mrdo 20210117
            }
		</div>
       )
    }

    async populateWeatherData() {
        try{
          const response = await fetch("https://tickau.engpool.com/cbt/ajax_question_load.cshtml?setno=6");
            const data = await response.json();
            this.setState({ questions: data, loading: false });
        }
        catch(err){
            console.log("문항을 가져오는데 실패했습니다.");
        }
      }
}

export default QuestionHome;
import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import Qtype1 from "../component/Qtype1";
import Qtype2 from "../component/Qtype2";
import Qtype3 from "../component/Qtype3";
import '../assets/question/Volume.js';
import Common from '../assets/Common.js';
import ReactSWFCompat from 'react-swf/compat';
import RecordRTCTest from '../component/reactRTC';
import QuestionHome from '../container/QuestionHome';
import { reduceEachTrailingCommentRange, isConstructorDeclaration, collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import AudioAnalyser from './AudioAnalyser';
import ReactMicCom from '../component/ReactMic';
import AnsChoice from '../component/ansChoice';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';
// import '../assets/sub/commain.css';



const SWF_ID_PREFIX = '__MyExternalInterfaceExample_SWFID_';
const SWF_CALL_NAME_PREFIX = '__MyExternalInterfaceExample_SWFCall_';
let nextUID = 0;



class Questions extends Component{

    constructor(props){
        super(props);

        this.state={
            _qno    :   props.qno,
            _sno    :   props.sno,   
            _uno    :   props.uno,     
            _QAudio :   props.questions.QueSound,
            _QAudio1 :   props.questions.QueSound1,
            _QImage :   props.questions.QueImage,        
            _QImage1 :   props.questions.QueImage1,        
            _QMov :   props.questions.QueMov,        
            _QText  :   props.questions.QueQuestion, 
            _QType  :   props.questions.QueType, 
            _CatNo  :   props.questions.CatNo, 
            _QueTips:   props.questions.QueTips,                         
            _QueSoundTip:   props.questions.QueSoundTip,                         
            _QueRightAnsTip:   props.questions.QueRightAnsTip,                         
            _Qcode:     props.questions.QCode, 
            _QUrl   :   props.qurl,            
            _PartInfo:  props.partinfo,
            _Listen_timer: null,
            _Response_timer: null,
            _SayAgain_end_timer : null,
            _minutes:0,
            _seconds:30,
            stylePath:'../assets/question/qstyle.css'            ,
            _RecordTime: props.questions.RecTime,
            _ListenTime: 15,
            TInterval : null,
            _nTime:0,
            _StudyTime:props.questions.StudyTime,
            _WaitTime:props.questions.WaitTime,
            _QueTime:props.questions.QueTime,
            myRInterval:null,
            _PartTile       : ["Readback/Hearback","Checking / Clarifying / Confirming / Informing","Role-play & Follow-up"," Role-play, Abnormal/Emergency Related","Reporting"],
            _SayAgainInfo: 0,
            _userRecordingFile: "",
            _tryno:props.trycount,
            zoom:0,
            _dispAudioVisual:false,
            _mic:null,
            _nowStudyTime:0,
            _btnOnReplay:false

        }


        
        this.startTimer = this.startTimer.bind(this)
        this.startRTimer = this.startRTimer.bind(this)
        this._uid = nextUID++;

        window[SWF_CALL_NAME_PREFIX + this._uid] = this.loadedComplete.bind(this);
        this.GoNext = this.GoNext.bind(this)
        this.OnGoNextReady = this.OnGoNextReady.bind(this)
        this.lastquiz = props.lastquiz; //20210117 mdo
        this._timeSeconds = 0;
        this.video = null;
        
        
        /* Qtype 정의
            1	문제 텍스트	NULL
        2	문제 텍스트 + 음성	NULL
        3	문제 텍스트 + 음성 + 이미지	NULL
        4	문제 텍스트 + 이미지	NULL
        5	문제 텍스트 + 동영상	NULL
        6	문제텍스트+음성2개	NULL
        7	문제텍스트+녹음	NULL
        8	동영상듣기	NULL
        9	문제듣기	NULL
        10	문제텍스트+녹음	+ 객곽식	NULL
        */       
        
    }

    
    invokeSWFMyCallback(arg) {
        // Beware; SWF Player does not sufficiently escape serialized arguments.
        return this._swfPlayerNode.myCallback(arg);
      }

    SayAgain=()=>{
        
        this.PlayAudio(this.state._QAudio);
        //let id_SayAgain = document.querySelector('.control_panel #id_SayAgain')
        this.OnSayAgain();

    }
    PlayDirection=()=>{
        
        this.PlayAudio(this.state._QAudio1);
        //let id_SayAgain = document.querySelector('.control_panel #id_SayAgain')
        //this.OnSayAgain();

    }
    
    disableGoNext=()=>{
        

        // document.querySelector('#id_GoNext').src = "./images/btn_next_disable.png";
        // document.querySelector('#id_GoNext').init_load = false;
        // //InitButtion(id_GoNext, false);
        // document.querySelector('#id_GoNext').style.cursor = "default";
        // document.querySelector('#id_GoNext').onClick = null;

        //Common.__EnableButtion__(id_SayAgain, true);
        
    }

    enableGoNext=()=>{
        
        // document.querySelector('#id_GoNext').src = "./images/btn_next_normal.png";
        // document.querySelector('#id_GoNext').init_load = true;
        // //InitButtion(id_GoNext, false);
        // document.querySelector('#id_GoNext').style.cursor = "hand";

        //Common.__EnableButtion__(id_SayAgain, true);
        
    }    


    
    
    disableSayAgain=()=>{
        this.state._SayAgainInfo = 1;
        let id_SayAgain = document.querySelector('.control_panel #id_SayAgain')
        //let id_SayAgain = document.querySelector('#id_SayAgain')
        
        const styleSayAgain={
            cursor  :   "default",
            filter : "alpha(opacity=50)"
        }
        
        // id_SayAgain.src= "./Images/btn_sayagain_disable.png";
        // id_SayAgain.onClick = null;

        //Common.__EnableButtion__(id_SayAgain, true);
        
    }

    EnableSayAgain=()=>{

        // let id_SayAgain = document.querySelector('.control_panel #id_SayAgain')
        // //let id_SayAgain = document.querySelector('#id_SayAgain')
            
        //     id_SayAgain.style.cursor = "hand";
        //     id_SayAgain.style.filter = "alpha(opacity=100)";
        //     id_SayAgain.src= "./Images/btn_sayagain_normal.png";
        //     id_SayAgain.onClick=()=>{this.OnSayAgain() }
        
        //Common.__EnableButtion__(id_SayAgain, true);
        
    }

    OnSayAgain=()=>{
        
        
        /*
        this.state._SayAgainInfo = 1
        
        var id_SayAgain = document.querySelector('.control_panel #id_SayAgain')
        id_SayAgain.onClick = null;
        this.state._SayAgainInfo = 1;
        const styleSayAgain={
            cursor  :   "default",
            filter : "alpha(opacity=50)"
        }
        
        id_SayAgain.src= "./Images/btn_sayagain_disable.png";
        */
       if(this.state._SayAgainInfo===1)
        return;
        
       if(this.state._SayAgainInfo===0){
        this.state._SayAgainInfo = 1
        this.disableSayAgain();
            if(this.state.myRInterval)
                clearInterval(this.state.myRInterval);     
            this.StopAudio();
            this.DisablePreparationTime();
            this.SetListening();
        }        

        /*
        
        Common.__EnableButtion__(id_SayAgain, false);
        setTimeout(function() {
             document.querySelector('id_QTemplete').OnSayAgain();
             }, 10);

        setTimeout(function(){ 
            document.querySelector('id_QTemplete').DisableSayaAain(); 
            if(typeof document.querySelector('id_QTemplete').HeadsetEnable == 'function') document.querySelector('id_QTemplete').HeadsetEnable();
        }, 100);

        this.RecordStop();
        
        document.querySelector('id_GoNext').src = "./images/btn_next_normal.png";
        document.querySelector('id_GoNext').init_load = false;
        //InitButtion(id_GoNext, false);
        document.querySelector('id_GoNext').style.cursor = "default";
        document.querySelector('id_GoNext').onclick = null;
        
        //clearTimeout(this._Next_timer);
        
        //this.id_ProgressRec.parentNode.parentNode.className = "response line disabled";
        
        clearTimeout(this._Listen_timer);
        clearTimeout(this._Response_timer);
        clearTimeout(this._Listen_end_timer);
        clearTimeout(this._SayAgain_end_timer);
        
        //setTimeout(function() { id_MediaControl.SetEQMeterBandsColor(__RGB__(255, 255, 100), __RGB__(255, 255, 100), __RGB__(255, 255, 100)); }, 500);
        document.querySelector('id_RecLow').style.visibility = "hidden";
        document.querySelector('id_msg_warning').style.visibility = "hidden";
	    */
    }

    reStartRTimer=(_type)=>{
        if(this.state.myRInterval)
            clearInterval(this.state.myRInterval);            
            
        this.startRTimer('R');

        this.state._nTime = 0;        
    }


    startRTimer=(_type)=>{

        let self = this;

        self.refs.child._bListenTime=false;

        var _rate = 0
        this.state._nTime = 0        

        if(self.state.myRInterval)
            clearInterval(self.state.myRInterval);   

        console.log('pre interval=' + this.state.myRInterval);
        this.state.myRInterval = setInterval(() => {
            console.log('interval=' + this.state.myRInterval);
            

            if(_type =='S'){
                _rate = (this.state._nTime) / this.state._StudyTime
                console.log('study seconds='+this.state._nTime);
                document.querySelector('#id_StudyTime').innerHTML= parseInt(this.state._StudyTime) - parseInt(this.state._nTime)
                
            }
            else if(_type=='P'){
                _rate = (this.state._nTime) / this.state._WaitTime
                console.log('preparation seconds='+this.state._nTime);
                document.querySelector('#id_PlayTime').innerHTML= parseInt(this.state._WaitTime) - parseInt(this.state._nTime)
                
            }
            else{
                _rate = (this.state._nTime) / this.state._RecordTime
                console.log('record seconds='+this.state._nTime);
                // document.querySelector('.time_state').innerHTML= parseInt(this.state._RecordTime) - parseInt(this.state._nTime)                
                
                // document.querySelector('.time_state').innerHTML = parseInt(parseInt(this.state._nTime)/60) + " : " + ((parseInt(this.state._nTime)%60).legnth===1? '0'+(parseInt(this.state._nTime)%60) :parseInt(this.state._nTime)) ;   
                if(document.querySelector('.time_state'))
                document.querySelector('.time_state').innerHTML = ((parseInt(parseInt(self.state._nTime)/60)) < 10 ? '0'+(parseInt(parseInt(self.state._nTime)/60)) :(parseInt(parseInt(self.state._nTime)/60)))  + " : " + ((parseInt(self.state._nTime)%60) < 10 ? '0'+(parseInt(self.state._nTime)%60) :(parseInt(self.state._nTime)%60)) ;   
                
            }

            this.state._nTime ++;

            //console.log('record minutes='+this.state._minutes);
            

            
            if(_type=='S'){
                if(this.state._nTime > this.state._StudyTime ){
                    console.log('clear study seconds');
                    clearInterval(self.state.myRInterval);                                
                    this.DisableStudying();
                    this.OnInitLoad();  
                    this.state._SayAgainInfo = 0;          
                    this.SetListening();
                    
                    if(document.querySelector('#id_Question')){
                        if(this.state._QText =="")
                        document.querySelector('#id_Question').style = "visibility:hidden";
                        else{
                            document.querySelector('#id_Question').style = "visibility:";
                            document.querySelector('#id_Question').innerHTML  = this.state._QText;
                        }
                    }
                    
                }         
            }  
            else if(_type=='P'){
                if(this.state._nTime > this.state._WaitTime ){
                    console.log('clear preparation seconds');
                    clearInterval(this.state.myRInterval);                                
                    this.DisablePreparationTime();                                    
                    /*
                    this.OnInitLoad();            
                    this.SetListening();                
                    */
                    this.sendToRecordStart();  
                    this.PlayThingSound(); 
                    this.SetResponse(this.state._RecordTime);                
                }   
            }        
            else if(_type=='C'){ //qtype 10 개관식
                if(this.state._nTime > this.state._RecordTime ){
                    console.log('clear record seconds');
                    clearInterval(this.state.myRInterval);            

                    setTimeout( this.GoNext(), 2000);                   
                    
                }       
            }                       
            else {

                if(this.state._nTime > this.state._RecordTime ){
                    console.log('clear record seconds');
                    clearInterval(this.state.myRInterval);            
                    
                    this.sendToRecordStop()

                    //setTimeout( this.GoNext(), 2000);                   
                    
                }           
            }

            if(_type=='S')
                this.ProgressStudy(_rate);
            else if(_type=='P')
                this.ProgressListen(_rate)
            else
                this.ProgressRec(_rate)

                console.log('ntime=',this.state._nTime);

        }, 1000)
    }

    

    startTimer(_type){
        var self = this;

        this.state.TInterval = setInterval(() => {
                    
            // if (this._timeSeconds > 0) {                                             
                this._timeSeconds  = this._timeSeconds + 1; //mrdo 20210117
                this.state._nowStudyTime = this.state._nowStudyTime+1;
               
            // }
            
            // if (this._timeSeconds==0) {                //mrdo 20210117
            //         clearInterval(this.state.TInterval);
            // } 

            
            console.log('home seconds='+this._timeSeconds);

            this.SetExamRemainTime(this._timeSeconds,this._timeSeconds); //mrdo 20210117
        }, 1000)
    }

    SetExamRemainTime(sTime, nSecond)
    {
        var mObj = document.getElementById("id_RemainTime");
        if(mObj)
        {

            
            mObj.innerHTML = parseInt(nSecond/60) + " : " + nSecond%60;   
            if(nSecond < 600) 
            {
                //mObj.style.color = "red";
                /*
                setTimeout(function() 
                { 
                    var mObj2 = document.getElementById("id_RemainTime");
                    if(mObj2) mObj2.style.color = "#15455e"; 
                }, 500);
                */
            }
        }
    }    
    OnInitLoad(){              

        //this.SetExamRemainTime(this.GetRemainTime(), this.GetRemainTimeSec());
        //if(this.GetQImage() == "default.jpg") document.querySelector('id_QTemplete').location.replace("Type2.htm");
        //else document.querySelector('id_QTemplete').location.replace("Type1.htm");
        document.querySelector('body').scrollTo(0, 0);

        var sPart       = this.props.questions.QueSound.substring(1,2).toString();
        var sTask       = this.props.questions.QueSound.substring(3,4).toString();
        var sTaskSubName = "";
        var nQNo        = parseInt(this.state._qno)-1;        
        
        // document.querySelector('#id_Part').innerHTML = sPart;        

        // if(sPart=="1"&& sTask=="A")
        //     sTaskSubName =  "&nbsp;" + this.state._PartTile[0]
        // else if(sPart=="1"&& sTask=="B")
        //      sTaskSubName =  "&nbsp;" + this.state._PartTile[1]
        // else if(sPart=="2"&& sTask=="A")
        //     sTaskSubName = "&nbsp;" + this.state._PartTile[2]            
        // else if(sPart=="2"&& sTask=="B")
        //     sTaskSubName = "&nbsp;" + this.state._PartTile[3]                        
        // else if(sPart=="2"&& sTask=="C")
        //     sTaskSubName = "&nbsp;" + this.state._PartTile[4]                          
        
        //     document.querySelector('#id_Task').innerHTML = sTask + ".";
        //     document.querySelector('#id_PT_Name').innerHTML = sTaskSubName;

       // document.querySelector('#id_PT_Name').innerHTML = this.GetPartTaskName(sPart, sTask);
                
        var bFind = false;
        var taskNum = nQNo
        var self = this;
        bFind = this.DrawQuestionNo("1", "A", sPart, sTask, nQNo, bFind,this.props.qcount);                
        /*
        nQNo = nQNo - (parseInt(this.state._PartInfo.p1ta)+1);        

        bFind = this.DrawQuestionNo("1", "B", sPart, sTask, nQNo, bFind,this.state._PartInfo.p1tb);
        
        nQNo = nQNo - (parseInt(this.state._PartInfo.p1tb)+1);

        bFind = this.DrawQuestionNo("2", "A", sPart, sTask, nQNo, bFind,this.state._PartInfo.p2ta);
        nQNo = nQNo - (parseInt(this.state._PartInfo.p2ta)+1);            
        bFind = this.DrawQuestionNo("2", "B", sPart, sTask, nQNo, bFind,this.state._PartInfo.p2tb);        
         nQNo = nQNo - (parseInt(this.state._PartInfo.p2tb)+1);
        bFind = this.DrawQuestionNo("2", "C", sPart, sTask, nQNo, bFind,this.state._PartInfo.p2tc);   
        */
             
        
        //this.rndSoundFile();
        if(document.querySelector("#QueTip")){
            document.querySelector("#QueTip").innerHTML =this.state._QueTips;
            document.querySelector('#div_tips').style="display:none";
            document.querySelector('.btn_outline_tipShow').style="display:block";
        }

        if(document.querySelector('#btn-answer1'))
            document.querySelector('#btn-answer1').style.background="none";
        if(document.querySelector('#btn-answer2'))
            document.querySelector('#btn-answer2').style.background="none";
        if(document.querySelector('#btn-answer3'))
            document.querySelector('#btn-answer3').style.background="none";
        
         
        
    }

   

    DrawQuestionNo(sPart1, sTask1, sPart2, sTask2, nQNo2, bFind,_nQMax)
    {
        
        var sHtml = "";
        var nQNo2 = nQNo2+1;
        var proc_rate = (nQNo2 / _nQMax)*100 

        // for(var i = 0; i < _nQMax; i++)
        // {
        //     sHtml += "<li" + (bFind ? "" : " class='finish'") + ">" + (parseInt(i)+1) + "</li>";
        //     if(bFind == false && i == nQNo2) bFind = true;            

        // }
        // document.getElementById("id_quiznum").innerHTML = sHtml;

        sHtml = '<div class="state_bar" style="width: 40%;"><span>05</span> </div>'
        
        document.querySelector('.state_bar').style.width=proc_rate+'%';
        document.querySelector('.state_bar span').innerHTML=nQNo2 + '/' + this.props.qcount;
        // document.querySelector('.state_on').style.marginLeft= (parseInt(proc_rate)-2)+'%';

        //id_1_A
        return bFind;
    }

    /*
    EnableSayAgain(){    

        const id_SayAgain = {
            cursor : 'hand',
            filter : "alpha(opacity=100)"
        }        
        Common.__EnableButtion__(document.querySelector('id_SayAgain'), true);
        document.querySelector('_SayAgain').onclick = function() { this.OnSayAgain(); }
    }
    */

        GoPrev=()=>{
            var self = this;
            
            window.scrollTo(0, 0);

            if(parseInt(self.props.qno)==1){
                alert('이전문제가 더이상 없습니다.');
                return;
            }
            
            if(self.state.myRInterval)
                clearInterval(self.state.myRInterval);                                

            //let path = "/test/"+ self.state._sno + "/"+ (parseInt(self.props.qno) - 1);   
            let path = "/test/"+ self.state._sno + "/"+ (parseInt(self.props.qno) - 1) + "/" + this.props.trycount;      

        //  let hstory = useHistory();
            this.state._SayAgainInfo = 0

            this.disableGoNext();
            
            
            if(this.state._QType !=10 ){  
                //self.sendToRecordStop()
                    
                //self.refs.child.uploadFiles();                        
                self.refs.child.stopPlayRecording();

                self.refs.child.nextStaus = true;
                self.refs.child.stopRecording();
                
                setTimeout(function(){
                
                        self._setQuestions();                     

                        self.refs.child.nextStaus = false;
                        if(document.audio)
                            document.audio.src='';

                        self.props.history.push(path);    

                        }, 1500);

                
                
            }
            else{ //객관식
                

                // self._setQuestions();        
                
                    self.props.history.push(path); 
                
            }  
            
            
        }

        GoReload=()=>{
            /*
            if(this.state.myRInterval)
                clearInterval(this.state.myRInterval);                                
                */
            
            if(this.state._btnOnReplay){
                this.state._btnOnReplay= false;
                document.querySelector('.control_audio_left #btnReplay').src="images/control_reply.png"
            }
            else{
                this.state._btnOnReplay= true;
                document.querySelector('.control_audio_left #btnReplay').src="images/control_reply1.png"
            }

            // let path = "/test/"+ this.state._sno + "/"+ (parseInt(this.props.qno));        
            // this.props.history.push(path);   

        }
    GoNext=()=>{
        var self = this;
        
        // if(self.refs.child && this.state._QType !=10 ){  
        //     if(this.refs.child.state._nPlayCount==1){
        //         alert("[Play] 버튼을 눌러, 녹음이 잘 되었는지 확인 후 진행해주세요.");
        //         return;
        //     }
        // }
        
        if(self.state.myRInterval)
            clearInterval(self.state.myRInterval);                                

        let path = "/test/"+ self.state._sno + "/"+ (parseInt(self.props.qno) + 1) + "/" + this.props.trycount;        
      //  let hstory = useHistory();
        this.state._SayAgainInfo = 0

        this.disableGoNext();
        
        window.scrollTo(0, 0);
        
        if(self.refs.child && this.state._QType !=10 ){  
            //self.sendToRecordStop()
                
            //self.refs.child.uploadFiles();                        
            self.refs.child.stopPlayRecording();

            self.refs.child.nextStaus = true;
            self.refs.child.stopRecording();
            
            setTimeout(function(){
            
                    self._setQuestions();                     

                    self.refs.child.nextStaus = false;
                    if(document.audio)
                        document.audio.src='';

                    self.props.history.push(path);    

                    }, 1500);

            
            
        }
        else{ //객관식
            if(this.state._QType==10 && !self.refs.child1.state._ansFlag){
                alert('답변을 선택해주세요');
                return;
            }

                self._setQuestions();                    
                self.props.history.push(path); 
            
        }

        
        
        
    }
    
    SetChoiceAnswer(nTime)
    {
            

        let self = this;
        //document.querySelector('#id_RecTime').innerHTML = parseInt((nTime*1000) / 1000);
        
        /*
        var nRet = id_MediaControl.MicRecordStart(parent.window.external.GetCurrPath() + "ResponseData/" + sFile);
        if(nRet == -1) window.external.Alert("녹음 시작 오류\r\n마이크 장치가 연결되어있지 않거나 오류가 발생하였습니다.");
        
       if(document.querySelector('.progress_box2 #id_ProgressRec')){
            document.querySelector('.progress_box2 #id_ProgressRec').parentNode.parentNode.parentNode.parentNode.className = "mic_box";    
        } 
        */
        
        //     document.querySelector('.progress_box #id_ProgressRec').parentNode.parentNode.className = "response line hide";        
        //     document.querySelector(' #responsechoice').className = "responsechoice line responsechoice_view";        
        //     //document.querySelector('responsechoice line hide').parentNode.parentNode.className = "responsechoice line response_view";        
            
        
        //     document.querySelector('#id_RecLow').style.visibility = "hidden";        
            
        //     //document.querySelector('#id_msg_warning').style.visibility = "visible";

        //     document.querySelector('.recoding .active .text').innerHTML="Choice";
            
        //    var id_GoNext = document.querySelector('#id_GoNext');           
        //    var id_QTemplete = document.querySelector('#id_QTemplete');
    
        //    //Common.__EnableButtion__(id_GoNext, true);
        //    id_GoNext.style.cursor = "hand";
           
           //self.startRTimer('R');
    
           //id_GoNext.onclick=()=>{ self.GoNext()};
            
           //if(typeof id_QTemplete.ResponseEnable == 'function') id_QTemplete.ResponseEnable();
            //setTimeout(function() { id_MediaControl.SetEQMeterBandsColor(_que_RGB__(255, 130, 130), __RGB__(255, 130, 130), __RGB__(255, 130, 130)); }, 50);

            this.refs.child1.showChoiceAns();
            
       
    }

    SetResponse(nTime)
    {
            

        let self = this;

        
            // document.querySelector('#id_RecTime').innerHTML = parseInt((nTime*1000) / 1000);
        
            /*
            var nRet = id_MediaControl.MicRecordStart(parent.window.external.GetCurrPath() + "ResponseData/" + sFile);
            if(nRet == -1) window.external.Alert("녹음 시작 오류\r\n마이크 장치가 연결되어있지 않거나 오류가 발생하였습니다.");
        //     */
        //    if(document.querySelector('.progress_box2 #id_ProgressRec')){
        //         document.querySelector('.progress_box2 #id_ProgressRec').parentNode.parentNode.parentNode.parentNode.className = "mic_box";    
        //     } 
        //     if(document.querySelector('.progress_box #id_ProgressRec')){
        //        document.querySelector('.progress_box #id_ProgressRec').parentNode.parentNode.className = "response line response_view";        
        //     }
    
            // document.querySelector('#id_RecLow').style.visibility = "hidden";        
            // //document.querySelector('#id_msg_warning').style.visibility = "hidden";
            // document.querySelector('.recoding .active .text').innerHTML="Recording";
            
            /*
            while(_AudioLevelList.length > 0) { _AudioLevelList.pop(); }
            _Response_timer = setTimeout( function() { ProgressRec(nTime, GetTickCount()); }, 100);
            */
    
        //    var id_GoNext = document.querySelector('#id_GoNext');
        //    var id_QTemplete = document.querySelector('#id_QTemplete');
    
        //    //Common.__EnableButtion__(id_GoNext, true);
        //    id_GoNext.style.cursor = "hand";
        //    if(document.querySelector('#id_Response'))
        //        document.querySelector('#id_Response').className= "mic_box"
    
    
           self.startRTimer('R');
    
           //id_GoNext.onclick=()=>{ this.OnGoNextReady(id_GoNext); };
            
           //if(typeof id_QTemplete.ResponseEnable == 'function') id_QTemplete.ResponseEnable();
            //setTimeout(function() { id_MediaControl.SetEQMeterBandsColor(_que_RGB__(255, 130, 130), __RGB__(255, 130, 130), __RGB__(255, 130, 130)); }, 50);

            
        

       
    }
    ProgressRec(nRate){        

       var nPgs = -119 + 119 * nRate;
        var nPgs1 = -200 + 200 * nRate;
        if(nPgs > 0) nPgs = 0;
        if(nPgs1 > 0) nPgs1 = 0;
        if(document.querySelector('.progress_box2 #id_ProgressRec'))
            document.querySelector('.progress_box2 #id_ProgressRec').style.backgroundPosition = nPgs1 + "px";

        if(document.querySelector('.progress_box #id_ProgressRec'))
            document.querySelector('.progress_box #id_ProgressRec').style.backgroundPosition = nPgs + "px";
        
        /*    if(document.querySelector('.progress_box .progress2'))
            document.querySelector('.progress_box .progress2').style.backgroundPosition = nPgs1 + "px";
            */
            
            /*
        var nCurr = GetTickCount() - nTickCount;
        document.querySelector('#id_RecTime').innerHTML = parseInt((nTime + 999 - nCurr) / 1000);
        
        if(nRate > 0) 
        {
            var nPgs = -119 + 119 * nCurr / nTime;
            if(nPgs > 0) nPgs = 0;
            id_ProgressRec.style.backgroundPosition = nPgs + "px";
            if(typeof id_QTemplete.ResponseProgress == 'function') id_QTemplete.ResponseProgress(nCurr, nTime);
        }
        */

        if(nRate > 0) 
        {
            //id_MediaControl.MicRecordStop();
            //setTimeout(function() { if(typeof document.querySelector('id_QTemplete').OnResponseEnd == 'function') document.querySelector('id_QTemplete').OnResponseEnd(); }, 500);
            return;
        }
        
        //_Response_timer = setTimeout( function() { ProgressRec(nTime, nTickCount); }, 100);
        
    }   
    ProgressStudy(nRate){        


        var nPgs = -119 + 119 * nRate;        
        if(nPgs > 0) nPgs = 0;        

        if(document.querySelector('#id_ProgressStudy'))
            document.querySelector('#id_ProgressStudy').style.backgroundPosition = nPgs + "px";

        if(nRate > 0) 
        {
            //id_MediaControl.MicRecordStop();
            //setTimeout(function() { if(typeof document.querySelector('id_QTemplete').OnResponseEnd == 'function') document.querySelector('id_QTemplete').OnResponseEnd(); }, 500);
            return;
        }
        
    }   
    OnGoNextReady=(obj)=>{
        /*obj.src = "../images/btn_confirm_normal.png";
        obj.init_load = false;
        */
        
        var self = this;
        obj.onclick = function() { setTimeout( function() { self.sendToRecordStop();}, 200); } //self.GoNext();         
    }
   
    SetPreparation=()=>{    
        const _WaitTime = this.state._WaitTime;
        //document.querySelector('#id_ProgressStudy').parentNode.parentNode.className = "studying line studying_view";
        //setTimeout( function() { this.ProgressStudy(nTime, this.GetTickCount()); }, 100);

        //   document.querySelector('#id_ProgressStudy').parentNode.parentNode.className = "studying line studying_view disabled";

         

        this.DisableStudying();    
        this.DisabledRecording();
            if(document.querySelector('#id_listen_title'))
                document.querySelector("#id_listen_title").src = "./images/title_2_2.gif";     

        


    var nRet;    

    
    document.querySelector('#id_ProgressStudy').className = "studying line studying_view";
    

    document.querySelector('#id_PlayTime').innerHTML = this.state._WaitTime; //parseInt((id_MediaControl.GetDuration() + 999) / 1000);
    if(document.querySelector('#id_Listen'))
        document.querySelector('#id_Listen').className = "headset_box";

    if(document.querySelector('.progress_box #id_ProgressListen'))
        document.querySelector('.progress_box #id_ProgressListen').parentNode.parentNode.className = "response line response_view"; 
        
    if(document.querySelector('#id_listen_title'))
            document.querySelector("#id_listen_title").src = "./images/title_2_2.gif";
    
        this.ProgressListen(0);

        document.querySelector('#id_ProgressListen').parentNode.parentNode.className = "listening line listening_view";                

        this.startRTimer('P');
    }    
    
    SetStudying(){    

        this.DisableListen();
        const id_StudyTime = this.state._StudyTime;
        document.querySelector('#id_ProgressStudy').parentNode.parentNode.className = "studying line studying_view";        
            document.querySelector('#id_ProgressStudy').className = "progress";
        //setTimeout( function() { this.ProgressStudy(nTime, this.GetTickCount()); }, 100);
        document.querySelector('#id_Question').innerHTML  = "You have 15 seconds to examin the 1st screen provided.";
        this.ProgressStudy(0);
                
        this.startRTimer('S');
    }
    DisableStudying(){    
        
        // if(document.querySelector('#id_ProgressStudy').className != "studying line hide1") 
        //         document.querySelector('#id_ProgressStudy').className = "studying line disabled";
                

        // if(document.querySelector('#id_ProgressStudy'))
        //     document.querySelector('#id_ProgressStudy').parentNode.parentNode.className = "studying line studying_view hide1";
    }
    DisablePreparationTime(){    
        // this.DisableListen();
        // if(document.querySelector('#id_listen_title'))
        //     document.querySelector("#id_listen_title").src = "./images/title_2.gif";
    }    
    

    DisableListen=()=>{
        // if(document.querySelector('#id_ProgressListen'))
        //     document.querySelector('#id_ProgressListen').parentNode.parentNode.className = "listening line disabled";

        // if(document.querySelector('#id_Listen'))
        //     document.querySelector('#id_Listen').className = "headset_box disabled";
        

        this.state._SayAgainInfo = 1;
        /*        
        if(document.querySelector('#id_SayAgain').onmousedown != null) 
        {
           
            //if(typeof document.querySelector('id_QTemplete').HeadsetDisable == 'function') document('id_QTemplete').HeadsetDisable();
            
            const id_SayAgain = {
                cursor:"hand",
                filter:"alpha(opacity=100)"
            }            
            
            //__EnableButtion__(id_SayAgain, true);
            this._SayAgain_end_timer = setTimeout(function() {

                    const id_SayAgain = {
                        cursor : "default",
                        filter : "alpha(opacity=50)",
                    }                    
                    
                    //__EnableButtion__(id_SayAgain, false);
                    document.querySelector('id_SayAgain').onClick = null;


                    //if(typeof document.querySelector('id_QTemplete').ListenDisable == 'function') document.querySelector('id_QTemplete').ListenDisable();
                }, 3000);
        }
        */

        
        //else if(typeof document.querySelector('id_QTemplete').ListenDisable == 'function') document.querySelector('id_QTemplete').ListenDisable();

        /*
        const id_SayAgain = {
            cursor : "default",
            filter : "alpha(opacity=50)",
        }                    
        
        //__EnableButtion__(id_SayAgain, false);
        document.querySelector('id_SayAgain').onClick = null;
        */
       this.disableSayAgain();

    }    

    PlayThingSound=()=>{
        this.PlayAudio("thing.mp3");
    }  
    
    componentDidMount(){

    
            this.startTimer();
    

            /*if(this.state._qno==2)
                this.startTimer();
                */

            this.OnInitLoad();
   //this.PlayAudio(this.state._QAudio);

      //this.PlayAudio(this.state._QAudio);

            if(document.querySelector('#id_Question')){
                if(this.state._QText ==""){
                    document.querySelector('#id_Question').style = "visibility:hidden";
                    if(this.state._QImage.indexOf("default") === -1)
                        document.querySelector('#id_Question').parentNode.style = "visibility:hidden";
                }
                else{
                    document.querySelector('#id_Question').style = "visibility:";
                    document.querySelector('#id_Question').innerHTML  = this.state._QText;
                }
            }
            
                
            if(this.state._StudyTime>0){
                this.DisableStudying();
                this.SetStudying();
                
            }
            /*
            else if(this.state._WaitTime>0){
                this.DisablePreparationTime();
                this.SetPreparation();
            }
            */
           else if(this.state._QType==10){            
            //this.DisabledRecording();
            this.state._SayAgainInfo = 0;
            this.SetListening();
            this.SetChoiceAnswer();
            
           }
            else{
                this.DisableListen();
                this.DisabledChoiceAns();
                //this.DisabledRecording();
                this.state._SayAgainInfo = 0;
                this.SetListening();
            }
                
                //this.thisMovie('kau_record').addEventListener('loadedComplete', this.loadedComplete); 2020916
                //this.EnableSayAgain();

                //this.state._userRecordingFile = this.rndSoundFile();
                

            //시간설정

            //ui.convertHtml("");

            
            this.disableGoNext();
            
        }
        componentDidUpdate(){

            //this.sendToRecordStop();

            this.OnInitLoad();            

            if(document.querySelector('#id_Question')){

                if(this.state._QText =="")
                document.querySelector('#id_Question').style = "visibility:hidden";
                else{
                    document.querySelector('#id_Question').style = "visibility:";
                    document.querySelector('#id_Question').innerHTML  = this.state._QText;
                }
                /*
                if(this.state._QText =="")
                    document.querySelector('#id_Question').className="descript_box hide";    
                else{
                    document.querySelector('#id_Question').innerHTML  = this.state._QText;
                    document.querySelector('#id_Question').className="descript_box";
                }
                */
            }            
            this.StopAudio();
            if(this.state._StudyTime>0){
                this.SetStudying();
                this.disableSayAgain();
            }
            /*
            else if(this.state._WaitTime>0)                
                this.SetPreparation();
                */
            else if(this.state._QType==10){
                this.SetListening();
            //this.DisabledRecording();
            this.state._SayAgainInfo = 0;
            this.SetChoiceAnswer();
           }
            else{
                this.DisableListen();
                this.DisabledRecording();
                this.state._SayAgainInfo = 0;
                this.SetListening();
            }

            //this.state._userRecordingFile = this.rndSoundFile();
            
            // document.querySelector('#id_SayAgain').onClick = function(){ this.SayAgain() }

            //his._setQuestions();
            
            this.disableGoNext();
        }

        componentWillUpdate(){
            console.log('componentWillUpdate');
            this.StopAudio();
        }

        
   
        _setQuestions=()=>{


            const apiUrl = 'https://tickau.engpool.com/cbt/ajax_question_save.cshtml';
            var callback = "callback";
            var CourseNo   = this.state._sno;    
            var UserNo = window.users.userno;     // R:Radet, T:Tower
            var QueNo = this.state._qno;
            var QCode = this.state._Qcode;

            if(this.state._QType==10){                       
                if(this.refs.child1.state._ansFlag !=false){
                    UserAns = this.refs.child1.state._ansFlag;
                }
            }else{
                if(this.refs.child)
                    var UserAns = this.refs.child.recFileName;
            }
            
            
            var AnsCheck = "anscheck";
            var TryCount = this.state._tryno;

            var optionAxios = {
                headers: {
                    //'Content-Type': 'application/x-www-form-urlencoded'
                    //'Content-Type': 'multipart/form-data'            
                    'Content-Type': 'access-control-request-method' 
                    
                }
            }

            let self = this;
            let form = new FormData() 
            form.append('cno', CourseNo)
             form.append('uno',UserNo) 
             form.append('qno',QueNo) 
             form.append('qcode',QCode) 
             form.append('userans',UserAns) 
             form.append('anscheck',AnsCheck) 
             form.append('tryno',TryCount) 

             form.append('time',this.state._nowStudyTime)  //mrdo 20210117
             form.append('flag',this.props.flag)  //mrdo 20210117
             

             axios.post(apiUrl, form) 
             .then( response => { 
                 console.log('response : ', JSON.stringify(response, null, 2)) 
                //  self.state._nowStudyTime  = 0;
                 console.log('저장완료')
                }
            )
             .catch( error => { 
                alert('문항 저장에 실패했습니다.');
                return;
                console.log('failed', error)
             })
           
        }
    
        
        componentWillReceiveProps(nextProps){
            console.log('componentWillReceiveProps');
            if(String(this.props.qno) != "undefined" && this.state._qno != nextProps.qno){
                this.setState({
                    _qno    : nextProps.qno,
                    _QAudio :   nextProps.questions.QueSound,
                    _QAudio1 :   nextProps.questions.QueSound1,
                    _QImage :   nextProps.questions.QueImage,        
                    _QImage1 :   nextProps.questions.QueImage1,        
                    _QText  :   nextProps.questions.QueQuestion, 
                    _QType  :   nextProps.questions.QueType, 
                    _CatNo  :   nextProps.questions.CatNo, 
                    _QMov :   nextProps.questions.QueMov,        
                    _QueTips:   nextProps.questions.QueTips, 
                    _QUrl   :   nextProps.qurl,            
                    _PartInfo:  nextProps.partinfo,
                    _RecordTime: nextProps.questions.RecTime,
                    _StudyTime: nextProps.questions.StudyTime,
                    _WaitTime: nextProps.questions.WaitTime,
                    _ListenTime: 15,
                    _Qcode:     nextProps.questions.QCode,
                    _nowStudyTime:0,
                    _QueSoundTip:   nextProps.questions.QueSoundTip,                         
                    _QueRightAnsTip:   nextProps.questions.QueRightAnsTip,                         
                    
                })

                //this.sendToRecordStop();
                // this.ProgressRec(0);
                // this.SetListening();
                //this._setQuestions();

                if(this.state._QType==10){
                    this.refs.child1.state._ansFlag = false 
                }
                 else{                    
                    this.refs.child.state._nRecCount = 1;
                    this.refs.child.state._nPlayCount = 1;
                 }
            }
        }

      

        togglePlayPause(){

            if(this.state.audio.paused){
                // btn.className = 'pause';
                this.state.audio.play();			
            }
            else{              //btn.className = "play";
                this.state.aduio.pause();
            }
        }

        StopAudio() {
            if (this.audio) {
                this.audio.pause();
                this.audio.currentTime = 0;
                this.audio.remove();
            }
        }

        StopVideo() {
            if (document.querySelector('video')) {
                document.querySelector('video').pause();                
            }
        }

        PlayVideo=(_sndfile)=> {
            


            let self = this;
            if(_sndfile == "thing.mp3")
                console.log("thing start");

            this.video = document.querySelector('video');            
            this.juicePos1 = 0;
            // this.video.src = this.state._QUrl  + "/mov" + "/" + _sndfile;            
            
            this.video.play(); //avoid the user has not interacted with your page issue
            

            this.video.onended = function () {
                // self.audio.remove(); //remove after playing to clean the Dom

                if(_sndfile == "thing.mp3")
                console.log("thing stop");
                
               console.log("audio remove")
            };
          
            
            this.video.addEventListener("timeupdate", function (_obj) {
               
                if(_sndfile == "thing.mp3")
                    return;

                    // console.log('playaudio _bListenTime='+self.refs.child.state._bListenTime);


                // self.juicePos1 = self.video.currentTime / self.video.duration;

                // if(document.querySelector('#id_PlayTime'))
                //     document.querySelector('#id_PlayTime').innerHTML = parseInt(self.audio.duration)-parseInt(self.audio.currentTime);
                                

                
                //     self.ProgressListen(self.juicePos);

                // console.log(self.juicePos);

                if(self.juicePos==1){

                    // self.DisableListen();

                    
                    
                        if(_sndfile != "thing.mp3" && self.state._WaitTime>0){
                            // self.DisablePreparationTime();
                            // self.SetPreparation();                            
                        }
                        else{                     

                            setTimeout(function(){
                            
                              //  self.DisablePreparationTime();
                                self.PlayThingSound(); 
                                self.enableGoNext();

                                // if(self.state._QType==10){
                                //     self.SetChoiceAnswer(self.state._RecordTime);
                                
                                // }
                                // else{
                                //     self.SetResponse(self.state._RecordTime);
                                    
                                //     if(self.props.questions.AnsType != "N")      
                                //         self.refs.child.state._bListenTime=false; //리스닝타임 종료

                                //         // console.log('listen time :'+ false);
                                //         // self.sendToRecordStart();                         
                                //     }

                                 }, 1500);
                            
                        }
                    
                }
            });


        }
        onPlay=(evt)=>{
            this.StopAudio();
            this.PlayVideo(this.state._QMov);
        }
        PlayAudio=(_sndfile)=> {

          

            this.StopAudio();
            var self = this;            
            //this.refs.child.state._bListenTime=true; //리스닝 시작
            // this.refs.child.setState({
            //     _bListenTime:true
            // })
            console.log('listen time :'+ true);



            if(_sndfile == "thing.mp3")
                console.log("thing start");

            this.audio = document.createElement('audio');            
            this.juicePos = 0;
            this.audio.src = this.state._QUrl  + "/audio" + "/" + _sndfile;
            this.audio.style.display = "none"; //added to fix ios issue
            this.audio.autoplay = true; //avoid the user has not interacted with your page issue
            document.body.appendChild(this.audio);
            this.audio.onended = function () {
                self.audio.remove(); //remove after playing to clean the Dom

                if(_sndfile == "thing.mp3")
                console.log("thing stop");
                
               console.log("audio remove")
            };
             
            
          
            
            this.audio.addEventListener("timeupdate", function (_obj) {
               
                if(_sndfile == "thing.mp3")
                    return;

                    // console.log('playaudio _bListenTime='+self.refs.child.state._bListenTime);

                self.juicePos = self.audio.currentTime / self.audio.duration;

                if(document.querySelector('#id_PlayTime'))
                    document.querySelector('#id_PlayTime').innerHTML = parseInt(self.audio.duration)-parseInt(self.audio.currentTime);
                                

                
                    self.ProgressListen(self.juicePos);

                console.log(self.juicePos);

                if(self.juicePos==1){



                    self.DisableListen();

                    
                    
                        if(_sndfile != "thing.mp3" && self.state._WaitTime>0){
                            self.DisablePreparationTime();
                            self.SetPreparation();                            
                        }
                        else{                     

                            setTimeout(function(){
                            
                                self.DisablePreparationTime();
                                self.PlayThingSound(); 
                                self.enableGoNext();

                                if(self.state._QType==10){
                                    self.SetChoiceAnswer(self.state._RecordTime);
                                    if(self.state._btnOnReplay)
                                    self.PlayAudio(self.state._QAudio);
                                }
                                else{
                                    // self.SetResponse(self.state._RecordTime);
                                    
                                    if(self.props.questions.AnsType != "N")    
                                            self.refs.child.state._bListenTime=false; //리스닝타임 종료
                                    else{
                                            if(self.state._btnOnReplay)
                                            self.PlayAudio(self.state._QAudio);
                                        }

                                    

                                        // console.log('listen time :'+ false);
                                        // self.sendToRecordStart();                         
                                    }

                                    

                                }, 1500);
                            
                        }
                    
                }
            });

        }


        ProgressListen(nRate)
        {
           
            //var nCurr = GetTickCount() - nTickCount;
            //document.querySelector('#id_PlayTime').innerHTML = parseInt((nTime + 999 - nCurr) / 1000);
            if(document.querySelector(".audio_bar")){
            
                /*
                document.querySelector("#id_ProgressListen").style.backgroundImage = "url(./images/progress_add.gif)";               
                document.querySelector("#id_listen_title").src = "./images/title_2_2.gif";
                */
              /* if(this.state._WaitTime >0){

                
                if(document.querySelector('#id_listen_title'))
                    document.querySelector("#id_listen_title").src = "./images/title_2_2.gif";         
                } 
                */    
               
                
                    var nPgs = 275 * nRate;
                    // if(nPgs > 0) nPgs = 0;
                    document.querySelector('.audio_bar').style.width = nPgs + "px";
            
                
                if(nRate > 0) 
                {
                    /*
                    this.state._Listen_end_timer = setTimeout(function() {                     
                        if(typeof document.querySelector('id_QTemplete').OnListenEnd == 'function') 
                            console.log('33');
                        //id_QTemplete.OnListenEnd(id_MediaControl.PlayStep); 
                        }, 500);
                    return;
                    */
                
                }  
        }
            
            //this.state._Listen_timer = setTimeout( function() { this.ProgressListen(nTime, nTickCount, bAddTime); }, 100);
            
        }    
        
        DisabledRecording=()=>{
            if(document.querySelector('.progress_box2 #id_ProgressRec')){
                document.querySelector('.progress_box2 #id_ProgressRec').parentNode.parentNode.parentNode.parentNode.className = "mic_box disabled";    
            }
    
            
             if(document.querySelector('.progress_box #id_ProgressRec')){
                if(this.state._QType==10){  
                    document.querySelector('.progress_box #id_ProgressRec').parentNode.parentNode.className = "responsechoice line";        
                }
                else{
                    document.querySelector('.progress_box #id_ProgressRec').parentNode.parentNode.className = "response line response_view disabled";        
                }
             }


            /*            
            if(document.querySelector('#id_ProgressRec'))
                document.querySelector('#id_ProgressRec').parentNode.parentNode.className = "response line response_view disabled";
            */
            let id_GoNext = document.querySelector('#id_GoNext');            
           
        }

        DisabledChoiceAns=()=>{
            //document.querySelector('responsechoice line hide').parentNode.parentNode.className = "responsechoice line response_view";  
            if(document.querySelector(' #responsechoice')){
                document.querySelector(' #responsechoice').className = "responsechoice line hide";      
            } 
            /*            
            if(document.querySelector('#id_ProgressRec'))
                document.querySelector('#id_ProgressRec').parentNode.parentNode.className = "response line response_view disabled";
            */
           
        }        
        
        SetListening(sFile, sPlayStep)
        {
            
            if(this.state._QAudio==""){                
                if(document.querySelector('.control_audio_box')){
                    document.querySelector('.control_audio_box').className = 'control_audio_box hide';               
                    return        
                }
            }
            else{
                 if(document.querySelector('.control_audio_box.hide'))
                    document.querySelector('.control_audio_box.hide').className = 'control_audio_box';
                    
                

            }
                
                

            this.PlayAudio(this.state._QAudio);

            // if(this.state._QMov!="")
            //     this.PlayVideo(this.state._QMov); mrdo 20210224
            

            this.DisableStudying();
            if(this.state._QType !=10 ){
                this.DisabledRecording();            
                this.DisabledChoiceAns();
            }
            

            if(this.state._SayAgainInfo === 0)
                this.EnableSayAgain();
            else    
                this.disableSayAgain();

            var nRet;
            
            
        
           this.ProgressListen(0);
        }

        componentWillMount(){
            console.log("will mount");
        }

        componentWillUnmount(){
            
            this.StopAudio();
            let self = this;
            if(self.state.myRInterval)
                clearInterval(self.state.myRInterval);                                

        }

        thisMovie(movieName){
            return document[movieName];
        }	

        showVisualize(_this,mic){
            _this.state._dispAudioVisual= mic;
            //_this.setState({dispAudioVisual: mic});
        }
        sendToRecordPlay=()=>{
            //record stop()            
            this.sendToRecordStop();
            this.refs.child.playRecording();
            //this.setState({_dispAudioVisual:true})

            /* 20200916
            this.thisMovie('kau_record').receive3(this.state._userRecordingFile);
            console.log("record start")
            */
        }
        
        sendToRecordStart=()=>{
            
            this.sendToRecordStop();
            this.refs.child.startRecording();
            //this.setState({_dispAudioVisual:true})

            /* 20200916
            this.thisMovie('kau_record').receive3(this.state._userRecordingFile);
            console.log("record start")
            */
        }

        sendToRecordStart1=()=>{
            //record stop()            
            this.thisMovie('kau_record').receive3(this.state._userRecordingFile);
            console.log("record start")
        }        
        sendToRecordStop1=()=>{
            //record stop()

            //this.DisabledRecording();

            if(this.thisMovie('kau_record')){
              
               console.log("record stop")
               this.ProgressRec(0);               
               this.thisMovie('kau_record').receive2();
            }            
        }    
        sendToRecordStop=()=>{
            //record stop()

            //this.DisabledRecording();            
            //this.refs.child.stopRecording();

            if(this.state.myRInterval>0)
                clearInterval(this.state.myRInterval);            
            
            /*
            if(this.thisMovie('kau_record')){
              
               console.log("record stop")
               this.ProgressRec(0);               
               this.thisMovie('kau_record').receive2();
            } 
            */           
        }        
            
        loadedComplete=()=>{

            console.log('flash compeleted')
            
        }        

      

        rndSoundFile=()=> {
            var _soundFile= null;
            var _curDate= new Date();
            var _mDate = "";
            var _mMonth = "";
            var _mHour = "";
            var _mSecond = "";
            var _mMinute = "";
            var _ymd = "";
            
            if(_curDate.getMonth() < 10)
                _mMonth = "0" + (_curDate.getMonth()+1)
            else	
                _mMonth = "" + (_curDate.getMonth()+1)
            
            if(_curDate.getDate() < 10)
                _mDate = "0" + _curDate.getDate()
            else
                _mDate = "" + _curDate.getDate()
            
            if(_curDate.getHours() < 10)
                _mHour = "0" + _curDate.getHours()
            else
                _mHour = ""	+ _curDate.getHours()
            
            if(_curDate.getMinutes() < 10)
                _mMinute = "0" + _curDate.getMinutes();
            else
                _mMinute = ""	+ _curDate.getMinutes();
            
            
            if(_curDate.getSeconds() < 10)
                _mSecond = "0" + _curDate.getSeconds()
            else
                _mSecond = ""	+ _curDate.getSeconds()
            
            
            _ymd = _curDate.getUTCFullYear() + _mMonth;		
            
            //_soundFile = "/kau/"+ _ymd + "/" + _curDate.getUTCFullYear() + _mMonth + _mDate + _mHour + _mMinute + _mSecond+"_"+this.state._uno+"_"+this.state._Qcode;
            _soundFile =  _curDate.getUTCFullYear() + _mMonth + _mDate + _mHour + _mMinute + _mSecond+"_"+this.state._uno+"_"+this.state._Qcode;
  
            console.log(_soundFile);

            this.state._userRecordingFile = _soundFile;
            
            return _soundFile
            
        }
        ShowTips=()=>{
            
             
            
            
            var newWin = window.open('test.html','pop','left=10,top=600,width=470, height=300, menubar=no , scrollbars = yes, status=no, toolbar=no');
            window.tip_data = this.state._QueTips;

            var self = this;

            

            // setTimeout(function() {
            //     if(!newWin.document.querySelector('#snd_rs')){
            //         setTimeout(function() {
            //             //newWin.document.querySelector('#snd_rs').src = self.state._QUrl  + "/audio" + "/" + self.state._QueSoundTip;
            //             newWin.document.querySelector('#QueRightAnsTip').innerHTML = self.state._QueTips;
            //             }, 1500);
            //     }
            //     else{
            //         // newWin.document.querySelector('#snd_rs').src = self.state._QUrl  + "/audio" + "/" + self.state._QueSoundTip;
            //         newWin.document.querySelector('#QueRightAnsTip').innerHTML = self.state._QueTips;
            //     }
            //     }, 1500);

            return;
            

            document.querySelector('#div_tips').style="display:block"
            document.querySelector('.btn_outline_tipShow').style="display:none";

        }
        ChoiceAnswer=(_obj)=>{
            let self = this;
        
            self.state._userRecordingFile = _obj.currentTarget.innerHTML;
            
            console.log(_obj.currentTarget.innerHTML + '을 선택했습니다.');
        }
    
        showRightAnswer=()=>{
            
           

            if(document.querySelector('#showRightAns').className=="modal"){
                document.querySelector('#showRightAns').className = "modal show"

                document.querySelector("#QueRightAnsTip").innerHTML= this.state._QueRightAnsTip;
                
                document.querySelector('#snd_rs').src = this.state._QUrl  + "/audio" + "/" + this.state._QueSoundTip;
                document.querySelector('#snd_rs').play();

            }
            else
                document.querySelector('#showRightAns').className="modal"
        }        

        closeRightAnswer=()=>{
            
           
                document.querySelector('#showRightAns').className="modal"
                document.querySelector('#snd_rs').pause();
                

        }        

        
        render(){
            const volSlideCtrlStyle={
                left:'135px', 
                top:'52px', 
                width: '51px', 
                height:'134px',
                position:'absolute',
                display: 'none',
                zindex:'999',
                background : '../mages/volume_control_bg.gif'
            }
            const volCtrlStyle={
                margintop:'9px',
                width:'100%',
                height:'124px'
            } 

            var _NextNo =  (parseInt(this.props.qno) + 1)

            //var dispAudioVisual = this.state.dispAudioVisual  ? <AudioAnalyser audio={this.state.dispAudioVisual} /> : ''

        return(
            <div>

                <form name="frm"></form>       

            <div className="control_state_box"> 
            {/* <div className="time_s">00:00</div> */}
             <div className="time_f"><span id="id_RemainTime">00:00</span></div> 
             {/* <div className="state_on" style={{marginLeft:"200px"}}></div> */}
             <div className="state_bar_bg"><div className="state_bar" style={{width:"40%"}}><span></span> </div></div>
         </div>
         
       
     <div class="correct_sign">      
                  
    </div>
        <div className="study_box"  style={{minHeight:"580"}}>
                { 
                            this.state.questions !=""  ? (
                            <Qtype3 qurl={this.state._QUrl} questions={this.props.questions} />                                
                        ) :  this.state._QImage.indexOf("default") > -1 ? (
                                <Qtype2 qurl={this.state._QUrl} questions={this.props.questions} />                                                            
                        ) : (
                            <Qtype1 qurl={this.state._QUrl} questions={this.props.questions} />  
                        )
                     }  
        </div>
                <div id="showRightAns" className="modal" tabindex="-1" role="dialog" style={{top:"40%",left:"40%"}}>
                    <div className="modal-dialog" role="document" style={{width:"400px",margin:"200px"}}>
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">모범답안</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeRightAnswer}>
                            <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <audio id="snd_rs" src={this.state._QUrl  + "/audio" + "/" + this.state._QueSoundTip} controls></audio>
                            <p style={{height:"120px",overflow:"auto"}}><span id="QueRightAnsTip" style={{fontSize:"20px"}}></span>
                            </p>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeRightAnswer}>Close</button>
                        </div> */}
                        </div>
                    </div>
                    </div>
        {(this.state._QImage1.trim() !="" || this.state._QMov.trim() !="") ? (
        <div className="study_img_bg" >
        <div className="study_img_box"  style={{minHeight:"580px;"}}>
            
            {(this.state._QMov !="" && this.state._QMov.indexOf("mp4")>0)? (
                <video src={this.state._QUrl + '/mov/' + this.state._QMov} controls  onPlay={this.onPlay}/>            
            ):(this.state._QImage1.trim() !='')?
                <img src={this.state._QUrl + '/image/' + this.state._QImage1}/>            
            :(
                <img src={this.state._QUrl + '/image/' + this.state._QImage1}/>            
            )
            }

        </div>
            </div>
            ):(<div></div>)}            
    
        {this.state._QueTips !="" ? (
            /*<div className="study_tips_bg">
            <div className="study_tips_box"  style={{minHeight:"80px;"}}>

                    <button className="btn_outline_tipShow" id="tipShow" onClick={this.ShowTips}>팁 보기</button>
        <p><div id="div_tips" style={{display:"none"}}><span id="QueTip"></span></div></p>
            </div>
            </div>
            */
            <img id="div_tips" src="images/tip_5.png" style={{position:"absolute",width:"100px",cursor:"pointer"}} onClick={this.ShowTips}/>
        ):(<div></div>)}

      
         <div className="control_audio_box" id="controlbox"> 
        
            <div className="control_audio_left">
                <img src="images/control_reply.png" id="btnReplay" onClick={this.GoReload}/>
                <img src="images/control_play.png" id="btnSayagain" onClick={this.SayAgain}/>
                {this.state._QAudio1 !="" ? (
                <button class="btn_direction_module" id="" onClick={this.PlayDirection}><li>지문▶</li><li>다시듣기</li></button>
                ):(
                    <div></div>
                )}
                  
         </div>
         <div className="control_audio_right">
         <div className="audio_bar_bg"><div className="audio_bar" style={{width:"30"}}></div></div>
         </div>
             
         </div>
        
        <div className="control_bottom_box">
            
            {this.state._QType==10 ?(
                <AnsChoice ref="child1" GoNext={this.GoNext} GoPrev={this.GoPrev} questions={this.props.questions} enableGoNext={this.enableGoNext}/>
                ) :
                (<ReactMicCom  width={158} showRightAns={this.showRightAnswer} GoNext={this.GoNext} GoPrev={this.GoPrev} ref="child" disptype={'Q'} filename={this.rndSoundFile()} qType={this.state._QType} reStartRTimer={this.reStartRTimer} sendToRecordStop={this.sendToRecordStop} />)
            }
                
            
              
        </div>     
        
            </div>
        )
    };
}
export default Questions;
import React, { Component } from 'react';
import Header from "../component/Header";
import '../assets/style.css';
import '../assets/question/TypeCommon.js';
import axios from 'axios';
// import Jumbotron from 'react-bootstrap/Jumbotron';
// import Toast from 'react-bootstrap/Toast';
// import Container from 'react-bootstrap/Container';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';


class ExamEnd extends Component{
    constructor(props){
        super(props);
        this.state = {
            _cno    :   this.props.match.params.courseno,
            _userno : window.users.userno,
        }
        
    }

    GoNext=()=>{
        window.opener = null;
        window.open('', '_self');
        window.close();
    }    
    ClearHistory=()=>{


        this.testAgain();
        return;
        
        let self = this

        const apiUrl = 'https://tickau.engpool.com/cbt/ajax_question_history_clear.cshtml';
        var callback = "callback";
        var CourseNo   = this.state._cno;    
        var UserNo = window.users.userno;     // R:Radet, T:Tower

        
        var optionAxios = {
            headers: {
                //'Content-Type': 'application/x-www-form-urlencoded'
                //'Content-Type': 'multipart/form-data'            
                'Content-Type': 'access-control-request-method' 
                
            }
        }
        let form = new FormData() 
        form.append('cno', CourseNo)
         form.append('uno',UserNo) 
         
         axios.post(apiUrl, form) 
         .then( response => { 
             console.log('response : ', JSON.stringify(response, null, 2)) 
             alert('기록이 초기화 되었습니다.');
             this.testAgain();
            }
        )
         .catch( error => { 
            alert('문항 저장에 실패했습니다.');
            return;
            console.log('failed', error)
         })
       
    }        

    testAgain=()=>{
        // this.props.history.push("/"+this.state._cno);
        this.props.history.push('/test/'+this.state._cno+'/1'+'/'+(parseInt(this.props.match.params.trycnt)+1));
    }



    render(){
        document.querySelector('body').style='background-color: #3ec2c6';
        return(
            <div>
            <Header />            

            <div className="Last_box">
            <img src="../../images/ico_study.png"/>
            <h1>수고하셨습니다.</h1>
            <div style={{marginTop:10}}><span id="stime">학습시간 {parseInt(parseInt(this.props.match.params.stime, 10)/60) + " : " + parseInt(this.props.match.params.stime, 10)%60}</span></div>
            
            <div className="btn_area">
                <button className="btn_retest" id="" onClick={this.ClearHistory}>다시풀기</button> 
                <button className="btn_close" id="" onClick={this.GoNext}>종료하기</button> 
            </div>
            </div>
{/*     
            <div className="body">
                <Container>
                    <Jumbotron>
                <h1>기초통합 종료</h1>
                <p> &lt;총 ( ) 문항&gt;</p>
                <h2>
                            <ul>
                            <li><span className="color_blu"> : </span> 수고하셨습니다 </li>
                                <li><span className="color_blu">
                                </span>
                                </li>
                            </ul>
                            <p class="mt10">&lt;진단피드백 안내&gt; </p>
                            <ul>
                                <li><span className="color_blu">□□ 일 후 홈페이지에서 채점자의 진단 피드백을 확인하실 수 있습니다</span>
        </li>
                            </ul>        
                            <p class="mt10">&lt;[결과]&gt; </p>
                            <ul>
                                <li><span className="color_blu"> 결과는 [학습리포트] > [진단테스트] 메뉴에서 확인 가능합니다. 
           
        </span>
        </li>
                            </ul>        
                            
                            </h2>
                </Jumbotron>       
        
                <Jumbotron>
                    <Button variant="primary" size="lg" onClick={this.GoNext}>레벨테스트 종료 </Button>
                </Jumbotron>       
                </Container>
                </div>       */}
            </div>
        )
    }
}
export default ExamEnd;
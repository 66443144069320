import { render } from '@testing-library/react';
import React from 'react';
import Magnifier from "react-magnifier";


const idStep1 = (props) => {
    
    if(props.questions.QueQuestion){
      return (
        
      <div className="quiz_contents">
      <div id="id_text_box" className="quiz_text_box">
        <div className="part"></div>
        <div className="task"></div>
        <div id="id_Question" className="question">&nbsp;{props.questions.QueQuestion}</div>
      </div>
      <img src={props.qurl + '/image/' + props.questions.QueImage} width="800px" height="490px"></img>
      
      {/* <div id="id_ImgBox" className="quiz_img_box">        
      <Magnifier src={props.qurl + '/' + props.questions.QueImage} width={800} /> 
      
      </div> */}
      </div>      
      )
  }
  else{
    return (
        
      <div className="quiz_contents">
      <div id="id_ImgBox" className="quiz_img_box">
        {/* <Magnifier src={props.qurl + '/image/' + props.questions.QueImage} width={800} />  */}
      <img src={props.qurl + '/image/' + props.questions.QueImage} width="800px" height="490px"></img></div>
      
    </div>
    )
  }
  
}

export default idStep1;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../assets/sub/commain.css';

class Qtypeinfo extends Component{
    constructor(props){
        super(props);


    }
    
    GoNext=()=>{
        this.props.history.push('/test/'+this.props.courseno+'/1');
    }    
    
    render(){
    return (
        <div className="body">
        <div className="navi2">  </div>
        <div className="contents_guide1">             
        {/*
            <div className="volume">
                <div className="volume_btn"> <img src="./images/btn_volume_normal.png" onclick="OnVolumeSliderShow();" onload="InitButtion(this, true);"/> </div>
                <div id="id_Slider_Volume" style={{left:'-49px', top:'47px', width: '51px', height:'134px', position:'absolute', display: 'none', zindex:'999', background : '../QuestionHtml/Images/volume_control_bg.gif'}} onclick="event.cancelBubble = true;">
                    <iframe id="id_VolumeCtrl" style={{margintop:'9px',width:'100%',height:'124px'}} src="../QuestionHtml/VolumeCtrl.htm" frameborder="0"></iframe>
                </div>
            </div>   
        */} 
            <div className="text_box">
                <div className="title">Information of question type </div>
                <div className="basic_type">
                    <table className="type_table" cellspacing="0" cellpadding="0" >
                        <thead>
                            <tr>
                                <th>Section</th>
                                <th>Type</th>
                                <th>Task Outline</th>
                                <th className="last">Note </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowspan="2">Part 1</td>
                                <td>Task A</td>
                                <td className="left">Readback/Hearback <br/>
                                    교신전송 청해 </td>
                                <td className="left last">조종사 : 관제지시에 대한 Full read-back <br/>
                                    관제사 : 사전관제지시 → 조종사 리드백 <br/>
                                    → 응답(hear back) </td>
                            </tr>
                            <tr>
                                <td>Task B</td>
                                <td className="left">Checking/Clarifying/Confirming/Informing <br/>
                                    교신문맥 듣고 단일응대 </td>
                                <td className="left last">일반영어가 포함된 상황내용 듣고, <br/>
                                    적절한 교신 응대 </td>
                            </tr>
                            <tr>
                                <td rowspan="3">Part 2</td>
                                <td>Task A</td>
                                <td className="left">Role-Play &amp; Follow-up <br/>
                                    연속교신상황, 롤 플레이 </td>
                                <td className="left last">비행/관제 단계가 최소 3개이상 <br/>
                                    연계된 상황에서 교신 응대<br/>
                                    :상황에 대한 설명 제공 </td>
                            </tr>
                            <tr>
                                <td>Task B</td>
                                <td className="left">Role-Play, Abnormal/Emergency Related <br/>
                                    비정상/비상상황, 롤 플레이 </td>
                                <td className="left last">단독 상황내에서 교신 응대 <br/>
                                    :상황에  대한 기본 설명 제공 </td>
                            </tr>
                            <tr>
                                <td>Task C</td>
                                <td className="left">Reporting <br/>
                                    교신상황 수행 후 질의응답 및 의견 제시 </td>
                                <td className="left last">Task B에 대한 질의 응답 및 의견 교환 </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="btn_box">
                <ul>
                    <li className="second">Next page in <span className="color_re" id="id_Second">&nbsp;</span> Seconds</li>
                        <li><img src="Images/btn_continue_normal.png" onload="InitButtion(this, true);" onClick={this.GoNext}/></li>
                </ul>
            </div>
            </div>
            
        </div>
    </div>
    )
}

}

export default Qtypeinfo;
import React, { Component } from 'react';
import { render } from '@testing-library/react';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';
import { useAlert } from "react-alert";

const alert = useAlert;

class ansChoice extends Component {         


    constructor(props) {
        super(props);
        this.state = {
          _ansFlag: false,
          _showFlag:false
        }
    
      }

    ChoiceAnswer=(_obj)=>{
        let self = this;
    
        this.setState({
            _ansFlag:_obj.currentTarget.innerHTML  
        })
               
        console.log(_obj.currentTarget.innerHTML + '을 선택했습니다.');

        
        if(_obj.currentTarget.innerHTML=='A')
            document.querySelector('#btn-answer1').style.background="#32686b";
        else
            document.querySelector('#btn-answer1').style.background="none";

        if(_obj.currentTarget.innerHTML=='B')
            document.querySelector('#btn-answer2').style.background="#32686b"
        else
            document.querySelector('#btn-answer2').style.background="none";

        if(_obj.currentTarget.innerHTML=='C')
            document.querySelector('#btn-answer3').style.background="#32686b"
        else
            document.querySelector('#btn-answer3').style.background="none";

        this.props.enableGoNext();
        {
            /*
            this.props.GoNext(); 
            this.state._QType===10?
                this.state._userRecordingFile !="A" && this.state._userRecordingFile !="B" && this.state._userRecordingFile !="C" && this.state._userRecordingFile !="D" ?
                {
                    Alert.show("보기 답을 선택해주세요")
                }          
            */
            
    
        }
    }

    AnswerCheckShow=()=>{        

        this.props.questions.QueRightAns === this.state._ansFlag ?(
            document.querySelector('.correct_sign').innerHTML= '<img className="offsign"  src="../Images/correctO1.png"></img>'
        ):(
            document.querySelector('.correct_sign').innerHTML= '<img className="onsign"  src="../Images/incorrectX1.png"></img>'
        )

        setTimeout(function() {
            document.querySelector('.correct_sign').innerHTML= ''
            }, 3000);

    }

    componentWillReceiveProps(nextProps){

        this.setState({
            _ansFlag : false
        });
    }

    showChoiceAns(){
        this.setState({
            _showFlag : true
        });

        
    }

    render(){
        
            //document.querySelector('#id_msg_warning').style.visibility = "visible";

            if(!this.state._showFlag){
                return(
                    <div>loading</div>
                )
            }


        if(this.props.questions.QueType==10){
                return (
                    <div>
                    <div className="control_left_mchoice">
                    <div style={{textAlign:"center",color:"white",fontWeight:"800"}}><span>Click your answer</span></div>
                    <button className="btn_outline" id="btn-answer1"  onClick={this.ChoiceAnswer} value="A">A</button>  
                    <button className="btn_outline" id="btn-answer2" onClick={this.ChoiceAnswer} value="B">B</button>  
                    <button className="btn_outline" id="btn-answer3"  onClick={this.ChoiceAnswer} value="C">C</button> 
                    </div>
                      <div className="control_right" style={{position:'relative',marginTop:'10px'}}>                      
                      <button className="btn_outline" id="" onClick={this.AnswerCheckShow} >정답확인</button>  
                      <img src="images/control_left.png" onClick={this.props.GoPrev}/>
                      <img src="../images/control_right.png" onClick={this.props.GoNext}/>
                    </div>

                    {/* // <div className="msg_warning">
                    // <button id="btn-answer" variant={this.state._ansFlag=='A' ?  'light':'primary' }  size="lg" onClick={this.ChoiceAnswer} value="A">A</button>
                    // <button id="btn-answer" variant={this.state._ansFlag=='B' ?  'light':'primary' }  size="lg" onClick={this.ChoiceAnswer} value="B">B</button>
                    // <button id="btn-answer" variant={this.state._ansFlag=='C' ?  'light':'primary' }  size="lg" onClick={this.ChoiceAnswer} value="C">C</button>                    
                    // <img id="id_msg_warning" style={{margin:'0 auto', visibility:''}} alt=""/></div>     */}

                    </div>
                )
            }
            else{
                return(
                    <div></div>
                )
        }   
        /*}
        else{
            return (
                <div></div>     
        
                
            )
        }
        */
    }
  
}

export default ansChoice;
import { render } from '@testing-library/react';
import React from 'react';
import Magnifier from "react-magnifier";



const idStep1 = (props) => {
    let content = props.questions._QText;        
            var _id_InfoStyle = props.questions._QText === "undefined" ? { visibility : "hidden"} : {}

    if(props.questions.QueQuestion){
      return (
        
      <div className="quiz_contents">
      <div id="id_text_box" className="quiz_text_box">
        <div className="part"></div>
        <div className="task"></div>
        <div id="id_Question" className="question">&nbsp;{props.questions.QueQuestion}</div>
      </div>
      <div id="id_ImgBox" className="quiz_img_box">
        {/* <Magnifier src={props.qurl + '/image/' + props.questions.QueImage} width={520} />  */}
      <img src={props.qurl + '/image/' + props.questions.QueImage} width="800px" height="490px"></img></div>
   
      <div className="direction_box">
                      <div className="title" id="id_Title">{props.questions.QueQuestion}
      </div>
                    <div className="dir_txt" id="id_Text">&nbsp;{content}</div>
                    <div className="dir_info" id="id_Info" style={_id_InfoStyle}>&nbsp;
                    
                    {/*<button onClick={this.GoNext}>Next</button>
                    <Link to={"/test/" + this.state._sno + "/"+(parseInt(this.props.qno) + 1)}><button>Next</button></Link>*/}
                    </div>
                </div>  
      
    </div>
      )
  }
  else{
    return (
        
      <div className="quiz_contents">
      <div id="id_ImgBox" className="quiz_img_box">
        {/* <Magnifier src={props.qurl + '/image/' + props.questions.QueImage} width={800} />  */}
      <img src={props.qurl + '/image/' + props.questions.QueImage} width="800px" height="490px"></img></div>
      
    </div>
    )
  }
  
}

export default idStep1;
import { ReactMic } from 'react-mic';
import React, { Component } from 'react';
import axios from 'axios';
 import Spinner from 'react-bootstrap/Spinner'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';
 import '../assets/sub/commain.css';
import { findAllByDisplayValue } from '@testing-library/react';
import AudioSpectrum from 'react-audio-spectrum';
import HowLongWillILoveYou from '../assets/media/How-Long-Will-I-Love-You.mp3'
import boydontcry from '../assets/media/boydontcry.mp3'
import CantStandTheRain from '../assets/media/CantStandTheRain.mp3'
import HoneyHoney from '../assets/media/HoneyHoney.mp3'

class ReactMicCom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
      _blob:null,
      _blobURL:null,
      dispSpinner:false,
      _bStop:false,     
      _bPlay:false,
      _nRecCount:1,
      _nPlayCount:1,
      _bListenTime:false,
      audioEle:null,
      
    }

    this.onData = this.onData.bind(this);
    this.onStop = this.onStop.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.audioEle1 = null;
    this.playlist = [boydontcry, HowLongWillILoveYou, CantStandTheRain, HoneyHoney,'/toeic/engpool/sound/2000m33_13.mp3']
    this.uploadtatus = false;
    this.recAudioUrl = "/upload/"
    this.recAudioFullUrl = null;
    this.recFileName = null;
    this.nextStaus = false;
    this.reRecStatus = false;
  }

  componentDidMount() {
    this.audioEle1 = document.getElementById('audio_rec')
    this.audioEle2 = document.getElementById('audio-element')
    
    
  }

  getRandomString() {
    if (window.crypto && window.crypto.getRandomValues && navigator.userAgent.indexOf('Safari') === -1) {
        var a = window.crypto.getRandomValues(new Uint32Array(3)),
            token = '';
        for (var i = 0, l = a.length; i < l; i++) {
            token += a[i].toString(36);
        }
        return token;
    } else {
        return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, '');
    }
}
  getFileName(fileExtension) {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var date = d.getDate();
    //return 'RecordRTC-' + year + month + date + '-' + this.getRandomString() + '.' + fileExtension;


    
    this.recFileName=this.props.filename+"_" + this.state._nRecCount;    
    console.log('recording file:'+this.recFileName);
    return this.recFileName+".mp3";
}
  startRecording = () => {  

    let self = this;
    if(this.props.disptype ==='Q'){
    console.log('_bListenTime='+this.state._bListenTime);
      if(this.state._bListenTime) return;

      if(this.state.record) return; //녹음중 클릭방지 

      // document.querySelector('#rec_text').innerHTML="Recording";
    }
    else{
      this.props.sendToRecordStart();
    }
    

    if(document.audio)
      document.audio.pause();


    // if(this.state._nRecCount>2 && this.props.disptype =='Q')
    // {      
    //     alert('재녹음은 1회만 가능합니다.');
    //     return;
    // }    
    
    if(this.props.disptype =='Q' &&  this.state.record){      

      this.reRecStatus = true
      this.stopRecording();

      setTimeout(function() {

        self.props.reStartRTimer();
        self.startRecording();
        return;
       },1500)
    }
    else{

      if(this.props.disptype =='Q')
        self.props.reStartRTimer();
    
      this.setState({     
        _bPlay: false, 
        _nRecCount:this.state._nRecCount+1
        });

        console.log('재녹음 _nRecCount=',this.state._nRecCount);
  
        setTimeout(function() {
          self.setState({ record: true});
        },1000)
    
      }
  }
 
  stopRecording = () => {
    
    this.setState({ record: false });    

    if(document.audio)
      document.audio.pause();  

    
      this.props.sendToRecordStop() 

    }    
    
    

  stopPlayRecording = () => {
    if(document.audio){
      document.audio.pause();
    }

      this.setState({ 
        _bPlay:false
      });
  }

  playRecording = () => {

    if(this.state._bListenTime) return;       

    if(document.querySelector('.recoding .active .text'))
      document.querySelector('.recoding .active .text').innerHTML="Playing";
    
    if(this.state.record)
      this.stopRecording();
    else{
      if(!document.audio){
        alert('녹음기능을 확인해주세요');
        return;
      }
      document.audio.play();       
      this.setState({ 
        _bPlay:true,
        _nPlayCount:this.state._nPlayCount+1
      });
    }      

  }

  playRecAudio = () =>{
    let self = this;
    
    setTimeout(function() {
      self.setState({ record: false });    
      if(!self.state._blob) return;
        //self.replaceAudio(URL.createObjectURL(self.state._blob));     
        self.replaceAudio(self.state._blobURL);     
        
            document.audio.currentTime = 0;
        
            //if(!document.audio.paused) return;
            if(!document.audio.paused) return;
            setTimeout(function() {
                //if(!document.audio.paused) return;
                if(!document.audio.paused) return;
              //document.audio.play();
                //document.audio.play()
                self.setState({
                  _bPlay:true,
   
                })             
                self.audioEle1.play();
               
                
            }, 100);
            
            //document.audio.play();
            //self.audioEle1.play();
   
        }, 300);
      
    
  }

  replaceAudio(src) {


    //var newAudio = document.createElement('audio');
    var newAudio = document.querySelector('audio');

    /*
    newAudio.controls = true;
    newAudio.autoplay = true;
    newAudio.id = 'audio_rec';
    newAudio.name = 'audio_rec';   
    */
    

    this.recAudioFullUrl = "https://tic1.engpool.com/upload/" + this.getFileName('mp3')
    //this.recAudioFullUrl = this.playlist[4];

    if(src) {
        newAudio.src = this.recAudioFullUrl;       
    }
    /*
    var parentNode = document.querySelector('audio').parentNode;
    parentNode.innerHTML = '';
    parentNode.appendChild(newAudio);
    */
    document.audio = newAudio;
    
    
    this.audioEle1 = document.audio;
    

     
    this.setState({
      //audioEle:document.audio
    })

    //this.state.audioEle.play();

    
}
  
  onData(recordedBlob) {
    console.log('chunk of real-time data is: ', recordedBlob);    
  }
 
  onStop=(recordedBlob)=>{

    console.log('recordedBlob is: ', recordedBlob);
    this.state._blob = recordedBlob.blob;
    this.state._blobURL = recordedBlob.blobURL;

    

    this.uploadFiles();
    
  }
    uploadFiles = () => {
        
    var blob = this.state._blob;
    var file = new File([this.state._blob], this.getFileName('mp3'), {
        type: 'audio/mp3'
    });

    //console.log('str='+src);


    //var options = new FileUploadOptions();
    
    //options.mimeType="multipart/form-data";
 
    var params = new Object();
    /*
    options.params = params;     
    var ft = new FileTransfer();
    console.log('upload start!!')
    */

    //spinnerplugin.show();

    var optionAxios = {
        headers: {
            //'Content-Type': 'application/x-www-form-urlencoded'
            'Content-Type': 'multipart/form-data'            
            //'Content-Type': 'access-control-request-method' 
            
        }
    }

    this.setState({
        dispSpinner:true
    })            

    let form = new FormData() 
    form.append('video-blob', file);
     
     axios.post("https://tic1.engpool.com/upload.cshtml", form) 
     .then( response => { 
         console.log('response : ', JSON.stringify(response, null, 2)) 
         console.log('저장완료')
         //alert('저장 완료했습니다..');
         this.setState({
            dispSpinner:false,
        })      

        //this.props.GoNext(); 
        
        if(this.nextStaus===false){          
          if(!this.reRecStatus){
              this.playRecAudio();
            }
            this.reRecStatus = false;
          
        }
        else{
          document.audio.src='';
          this.nextStaus = false;
          this.reRecStatus = false;
        }

        }
    )
     .catch( error => { 
        alert('저장에 실패했습니다. 관리자에게 문의주셔요');
        return;
        console.log('failed', error)
     })

}

play1 = () => {
  //this.audioEle1.play()
  
  this.audioEle1.play()
  

  this.setState({
    audioStatus1: 'PLAYING'
  })
}


componentWillUnmount = () => {
  console.log('will unmount!!');
}

  render() {
    if(this.state.dispSpinner){
      return(
          <div style={{textAlign: 'center'}}>
      <Spinner animation='border' role='status'>
      <span className='sr-only'>Loading...</span>
      </Spinner>
      </div>
      )

      
    }        

    if(this.props.qType==10){
      return(
      <div></div>
      )
    }


    let a_id = this.state.audioEle1 ? this.state.audioEle1.src : "";
          
    return (
    <div>
   
              <div style={this.props.disptype==='T'? {display:''}: {display:'none'}}>
              {
       this.state._bPlay === false ? (
         this.props.disptype === 'T' ? (
       <ReactMic
         record={this.state.record}
         width={this.props.width}
         className="sound-wave"
         onStop={this.onStop}
         onData={this.onData}
         strokeColor="#000000"
         backgroundColor="#dcdcdc"     
         visualSetting="frequencyBars"/>      
         // visualSetting="none"/>)
       ):
       (<ReactMic
         record={this.state.record}
         width={140}
         className="sound-wave"
         onStop={this.onStop}
         onData={this.onData}
         strokeColor="#000000"
         backgroundColor="#ffffff"             
         visualSetting="frequencyBars"/>))
     : (
     
       this.props.disptype === 'T' ? (
         <AudioSpectrum
       id="audio-canvas"
       height={110}
       width={this.props.width}
       audioId={'audio_rec'}
       capColor={'black'}
       capHeight={2}
       meterWidth={10}
       meterCount={512}
       meterColor={[
         {stop: 1, color: 'black'}
       ]}  
       gap={4}/>)
     :(
       <AudioSpectrum
       id="audio-canvas"
       height={70}
       width={this.props.width}
       audioId={'audio_rec'}
       capColor={'black'}
       capHeight={2}
       meterWidth={10}
       meterCount={512}
       meterColor={[
         {stop: 1, color: 'black'}
       ]}  
       gap={4}/>
     ))}
     </div>
        
      <div className="control_left">
  
        {/* <Button id="btn-start-recording" variant={this.state._nRecCount>2 ?  'default': (this.state._bPlay ? 'danger' : (this.state.record?  'default': (this.state._bListenTime ? 'default' :'default')))}  size="xm" onClick={this.startRecording} >{this.state.record ? "Record" : 'Record'}</Button>
        <Button id="btn-stop-recording" variant={this.state.record ?  'primary': (this.state._bPlay ?  'primary': (this.state._bListenTime ? 'default' :'default'))} size="xm" onClick={this.playRecording} >{this.state._bPlay ? 'Playing': 'Play'}</Button>         */}

          <button className={this.state._bPlay ? 'btn_normal_module' : (this.state.record?  'btn_record_module': (this.state._bListenTime ? 'btn_normal_module' :'btn_record_module'))}  id="" onClick={this.startRecording} ><img src="/images/microphone.png" width="25" style={{marginRight:"10px",border:"0 0 0 0 4",background:"white",borderRadius:"10px"}}/>Record</button>  
            <button className={this.state.record ?  'btn_rplay_module': (this.state._bPlay ?  'btn_playon_module': (this.state._bListenTime ? 'btn_playon_module' :'btn_rplay_module'))} id="" onClick={this.playRecording}  >Play</button>  
           <div className="time_state" id=""  >00:00</div>

        {/* <div className={this.props.disptype==="T" ? "btn_box1": "btn_box"}>
                        <button className={this.state._nRecCount>2 ?  'btn_response': (this.state._bPlay ? 'btn_response record_on' : (this.state.record?  'btn_response': (this.state._bListenTime ? 'btn_response' :'btn_response')))} id="btn-start-recording" onClick={this.startRecording} >Record</button> 
                        <button className={this.st.record ?  'btn_response play_on': (this.state._bPlay ?  'btn_response play_on': (this.state._bListenTime ? 'btn_response' :'btn_response'))} id="btn-stop-recording" onClick={this.playRecording} >Play</button> 

          </div> */}

        {
      /*
      <Button id="btn-stop-recording" variant={!this.state.record ?  'default':'primary' } size="xm" onClick={this.stopRecording} >Stop</Button>
      <Button id="btn-upload-recording" variant="primary" size="lg"onClick={this.uploadFiles} >Upload</Button>
       <audio id="audio-element"  preload="auto" 
               src={`${this.playlist[4]}`} controls 
              
              ></audio>
        */}
        
      </div>
      {this.props.disptype === 'Q' ? (
      <div className="control_right"  style={{position:'relative',marginTop:'10px'}}>
                
                <button className="btn_outline" onClick={this.props.showRightAns} id=""  >모범답안</button>  
                <img src="images/control_left.png" onClick={this.props.GoPrev}/>
                <img src="../images/control_right.png" onClick={this.props.GoNext}/>
             </div>)
             :''}

             <audio id="audio_rec" crossorigin="anonymous" preload="true"  src={a_id}></audio>
            
            
    </div>  


    );
  
  {/*
  else{
    return (
     
      <div>
        <ReactMic
          record={this.state.record}
          width={this.props.width}
          className="sound-wave"
          onStop={this.onStop}
          onData={this.onData}
          strokeColor="#000000"
          backgroundColor="#adaeb3" />
        
        <div>
        <Button id="btn-start-recording" variant={this.state.record ?  'danger':'primary' }  size="xm" onClick={this.startRecording} >Start</Button>
        <Button id="btn-stop-recording" variant={this.state._bPlay ?  'danger':'primary' } size="xm" onClick={this.playRecording} >Play</Button>
        <Button id="btn-stop-recording" variant={!this.state.record ?  'danger':'primary' } size="xm" onClick={this.stopRecording} >Stop</Button>

      <Button id="btn-upload-recording" variant="primary" size="lg"onClick={this.uploadFiles} >Upload</Button>
        </div>
        <div><audio controls autoPlay ></audio></div>

    
        
        </div>  
        
    );
    
  }
  */}
  }
}export default ReactMicCom;